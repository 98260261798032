import { createSlice } from "@reduxjs/toolkit";

const defaultState = {

    drawdownrequestCart: [

    ],
    paymentsCart: [

    ],
    addresses: [

    ],
    ddrTotalCost: 0,
    paymentsTotalCost: 0,
    hidePartialPayments:false
}

const cartReducer = createSlice({
    name: "cartReducer",
    initialState: defaultState,
    reducers: {
        updateDDRCart: (state, action) => {
            state = {
                ...state,
                drawdownrequestCart: action.payload.data,
                ddrTotalCost: action.payload.totalCost
            }
            return state;
        },
        updatePaymentsCart: (state, action) => {
            state = {
                ...state,
                paymentsCart: action.payload.data,
                addresses: action.payload.addresses,
                paymentsTotalCost: action.payload.totalCost
            }
            return state;
        },
        setHidePartialPayment: (state, action) => {
            state = {
                ...state,
                hidePartialPayments:action.payload
            }
            return state;
        }
    }
})

export const { updateDDRCart, updatePaymentsCart,setHidePartialPayment } = cartReducer.actions

export default cartReducer.reducer;