import { createSlice } from "@reduxjs/toolkit";

const defaultState = {

    lenderFilter: {},
    supplierFilter: {},
    dealerFilter: {},
    prospectDealerFilter: {},
}

const userManagement = createSlice({
    name: "userManagement",
    initialState: defaultState,
    reducers: {
        setDefaultFilter: (state, action) => {
            switch (action.payload?.key) {
                case 'lenders':
                    state.lenderFilter = action.payload?.value;
                    break;
                case 'supplier':
                    state.supplierFilter = action.payload?.value;
                    break;
                case 'dealer':
                    state.dealerFilter = action.payload?.value;
                    break;
                case 'prospectDealer':
                    state.prospectDealerFilter = action.payload?.value;
                    break;
                default:
                    return state;
            }
        }
    }
})    


export const { setDefaultFilter } = userManagement.actions;

export default userManagement.reducer;